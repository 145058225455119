export const useParseCode = (code: string): [number, string, number, number] => {
  if (!code) return [0, '', 0, 0];
  const [first, letter, last, temp] = code.split('-');
  const firstNum = parseInt(first);
  const lastNum = parseInt(last);
  const tempNum = parseInt(temp);
  return [
    isNaN(firstNum) ? 0 : firstNum,
    letter || '',
    isNaN(lastNum) ? 0 : lastNum,
    isNaN(tempNum) ? -1 : tempNum,
  ];
};
