import { useParseCode } from './useParseCode';

export const useSortByCode = (a: string, b: string) => {
  const parseCode = (code: string) => useParseCode(code);
  const [comp1, comp2, comp3, comp4] = [
    parseCode(a)[0] - parseCode(b)[0],
    parseCode(a)[1].localeCompare(parseCode(b)[1]),
    parseCode(a)[2] - parseCode(b)[2],
    parseCode(a)[3] - parseCode(b)[3],
  ];
  return comp1 || comp2 || comp3 || comp4;
};

export const useIsCode = (code: string) => {
  const regex = /^\d+(-[A-Za-z]+(-\d+(-\d+)?)?)?$/;
  return regex.test(code);
};
